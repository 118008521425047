import React, { useState, useEffect, useCallback } from 'react'
import { ModalPropsType } from './types'
import { animated, useSpring } from '@react-spring/web';
import { motion, AnimatePresence } from 'framer-motion'
//styles
import styles from './offcanvasModal.module.scss';
import classNames from 'classnames';

const OffcanvasModal = ({ show, children, onClose }: ModalPropsType) => {
    const [open, setOpen] = useState<boolean>(show);
    const opacity = useSpring({ opacity: open ? 1 : 0 });

    useEffect(() => {
        setOpen(show);
    }, [show])

    const close = useCallback(() => {
        setOpen(false);
        setTimeout(() => {
            onClose();
        }, 300);
    }, [onClose]);

    // if (!show) return null;
    return (
        <AnimatePresence>
            {show && (<>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.3 } }}
                    exit={{ opacity: 0, transition: { delay: 0.3, duration: 0.3 } }}
                    className={classNames(styles.container)}>
                    <motion.div onClick={close} className={classNames(styles.overlay_container)} />

                    <motion.div
                        initial={{ x: '-100%' }}
                        animate={{ x: 0, transition: { delay: 0.3, duration: 0.3 } }}
                        exit={{ x: '-100%', transition: { duration: 0.3 } }}

                        className={classNames(styles.subContainer)}>
                        {children}
                    </motion.div>

                </motion.div>
            </>)}
        </AnimatePresence>
    )
}

export default OffcanvasModal