import React, { useCallback } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import styles from './nearVenueSearch.module.scss'
import classNames from 'classnames';

function Loading() {

    const item = useCallback(() => {
        return (
            <div className={classNames(styles['row'], styles['align-center'], styles['mb-24'])}>
                <Skeleton style={{ width: 48, height: 48, borderRadius: 10, marginRight: 16, }} />
                <div>
                    <Skeleton style={{ width: 160, height: 16, borderRadius: 2, marginBottom: 4 }} />
                    <Skeleton style={{ width: 120, height: 15, borderRadius: 2 }} />
                </div>

            </div>
        )
    }, [])
    return (
        <SkeletonTheme baseColor='#202226' highlightColor='#444'>
            {item()}
            {item()}
        </SkeletonTheme>
    );
}

export default Loading;
