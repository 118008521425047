

import React, { useEffect, useState, useCallback } from 'react';
import { getOverallVenue } from '../../../../apis/getTotalVenues/getTotalVenues';
//styles
import styles from './nearVenueSearch.module.scss';
import classNames from 'classnames';
//Images                        
import Image from 'next/image';
import basketball from '../../../../../public/locatevenues/basketballapi.png';
import badminton from '../../../../../public/locatevenues/badmintonapi.png';
import cricket from '../../../../../public/locatevenues/cricketapi.png';
import star from '../../../../../public/locatevenues/ratingstars.png';
import dot from '../../../../../public/locatevenues/doter.png';
import { useRouter } from 'next/router';
import Loading from './loading';
import { VenueData } from '@/routes/venue/types';


const NearvenueSearch = () => {

  const [search, setSearch] = useState('');
  const [venues, setVenues] = useState<VenueData[]>([]);
  const [filteredData, setFilteredData] = useState<VenueData[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();



  const fetchVenues = useCallback(async () => {
    try {
      const response = await getOverallVenue();
      setVenues(response);
    } catch (error) {
      console.error('fetchVenues error', error);
    }
  }, []);

  useEffect(() => {
    fetchVenues()
  }, []);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchWord = event.target.value;
    let newplacename = venues;
    setSearch(searchWord);
    setLoading(true);
    const newFilter = newplacename?.filter((data: VenueData) => {
      return data?.venue?.name?.toLowerCase().includes(searchWord.toLowerCase());
    });
    setLoading(false);
    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearSearch = () => {
    setSearch("")
    setFilteredData([]);
  }
  const handleClick = (data: VenueData) => {

    let name = data?.venue?.name.replaceAll('-', ' ')
    name = name.replaceAll(' ', '-').toLocaleLowerCase();
    let city = data?.venue_city?.city?.trim()?.replaceAll(' ', '-').toLocaleLowerCase();
    let area = data?.venue?.area?.trim()?.replaceAll(' ', '-');
    let slug = `${name}-${area.toLocaleLowerCase()}-${data?.type.toLocaleLowerCase()}`;
    const specialCharactersRegex = /[^a-zA-Z0-9\s\-]/g;
    slug = slug.replaceAll('---', '-');
    slug = slug.replaceAll('--', '-');
    slug = slug.replaceAll(',', '');
    const cleanSlug = slug.replace(specialCharactersRegex, '');
    router.push(`/${city?.toLocaleLowerCase()}/sports-venue/${cleanSlug}`)
  }

  const gameType = (type: string) => {
    switch (type) {
      case "football":
        return <Image src={'/whitesportsicon/football_white.png'} alt='football' height={14} width={14} />;
      case "basketball":
        return <Image src={basketball} alt='basketball' height={14} width={14} />;
      case "cricket":
        return <Image src={cricket} alt='cricket' height={14} width={14} />;
      case "badminton":
        return <Image src={badminton} alt='badminton' height={14} width={14} />;
      case "swimming":
        return <Image className={styles['scale_1_5']} src={'/icons/swimming_grey.webp'} alt='swimming' height={14} width={14} />;
      case "pickleball":
        return <Image src={'/icons/pickleball_grey.webp'} alt='pickleball' height={14} width={14} />;
      case 'padel':
        return <Image src={'/whitesportsicon/paddle_icon.png'} alt='padel' height={14} width={14} />;
      case 'squash':
        return <Image src={'/whitesportsicon/squash_icon.png'} alt='squash' height={14} width={14} />;
      case 'tennis':
        return <Image src={'/whitesportsicon/tennie_icon.png'} alt='tennis' height={14} width={14} />;
      default:
        break;
    }
  }

  return (
    <div className={styles['constainer']}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#989A9E" viewBox="0 0 16 16">
        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
      </svg>

      <input
        type="text"
        className={styles.search_input}
        value={search}
        autoComplete="off"
        onChange={handleFilter}
        placeholder="Search for Venues"
      />

      {search && (<div className={classNames(styles["cursor-pointer"], styles['sub_3'], styles['blue3'])}
        onClick={clearSearch}>
        <p >Clear</p>
      </div>)}

      {search && (<div className={classNames(styles['search_container'])}>
        {loading ? <Loading /> : (<>
          {filteredData?.length !== 0 ? (
            <>
              {filteredData?.map((data: any, index: number) => {
                let reviewrating = data?.ratings_and_reviews?.rating;
                let type = data.type;
                const id = data._id;
                return (
                  <div key={index} className={styles.item} onClick={() => handleClick(data)}>
                    <Image src={data.venue.venue_display_picture} className={styles.item_image} alt=""
                      height={40} width={40} />
                    <div className={classNames(styles['column'], styles['width_100'])}>
                      <p className={styles.mainLocation}>{data.venue.name},&nbsp;
                        <p className={styles.main_area}>{data.venue.area}</p>
                      </p>
                      <div className={classNames(styles['icon_cont'])}>
                        <div style={{ marginTop: 5 }}>{gameType(type)}</div>
                        <Image src={dot} height={4} width={4} alt='dots' />
                        <Image src={star} height={14} width={14} alt='star' />
                        <div className={styles.ratings} style={{ marginLeft: -2 }}>{reviewrating}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <p className={classNames(styles['sub'], styles['white1'])}>No results found</p>
          )}
        </>)}
      </div>)}
    </div>
  )
}


export default NearvenueSearch;
