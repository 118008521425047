import { VenueData } from "@/routes/venue/types";
export interface getOverallVenueResponse {
    data: {
        status: string;
        message: string;
        data: VenueData[];
    }
}

export const getOverallVenue = async (
): Promise<VenueData[]> => {

    try {
        const response = await fetch(`https://api.turftown.in/api/v2/venue/get_all_venues`,{method: 'POST', body :JSON.stringify({})});
        const data = await response.json();
        const venues = data;
        return venues?.data;
    
    } catch (error) {
        throw error;
    }
};
